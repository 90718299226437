<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!-- SECTION PREVIEWER -->
        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-mineralgreen">
            <div class="container mx-auto">
                <div class="p-5 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">L'Entreprise</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- ABOUT -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="col-12 flex flex-col items-center">
                        <h1 class="text-center text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Bienvenue au coeur de International Bio Cosmetics</h1>
                        <div class="bg-lightgreen p-1 mt-3 w-72"></div>
                    </div>
                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <p class="text-2xl text-mineralgreen font-black font-epigrafica my-4">Notre coeur de métier, la cosmétique... mais NATURELLE !</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-medium mb-5">
                                    International Bio Cosmetics (IBC) est une entreprise spécialisée dans la fabrication, la production et la commercialisation 
                                    de produits cosmétiques essentiellement à base d’ingrédients et de plantes naturelles. <br><br>
                                    
                                    Créée et implantée en Côte d’ivoire depuis 2019, IBC propose par l’expertise de ses laboratoires, des produits de beauté 
                                    efficaces et innovants inspirés de nos recettes de grand-mère.
                                </p>
                                <p class="font-epigrafica text-center text-lightgreen text-3xl font-thin mb-5 mt-10 px-2 md:px-32">
                                    "...Nous souhaitons être le catalyseur de toutes les beautés, quelles qu'elles soient... "<br>
                                    <span class="text-base font-bold">Le Directeur général de IBC</span>
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <q-img width="80%" src="@/assets/media/ibc-international-bio-cosmetics-products-family.png" no-spinner />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <!-- VISION -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="col-12 flex flex-col items-center">
                        <h1 class="text-center text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Notre Mission</h1>
                        <div class="bg-lightgreen p-1 mt-3 w-28"></div>
                    </div>
                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <q-img class="img-smooth-zoom" width="100%" src="@/assets/media/ibc-international-bio-cosmetics-mission.png" no-spinner />
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <p class="text-2xl text-mineralgreen font-black font-epigrafica my-4">Révéler la beauté des femmes et des hommes par des produits de qualités inspirés de la nature</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-medium mb-5">
                                    IBC vous met au coeur de son activité et de ses offres, et parce que vous avez droit à ce qui se fait de mieux, 
                                    sinon à ce qui ce fait de meilleur; nous nous sommes donné pour mission de révéler votre beauté aux yeux du monde, hommes et femmes
                                    de tout horizon. <br><br>


                                </p>
                                <p class="font-epigrafica text-center text-lightgreen text-3xl font-thin mb-5 mt-10 md:px-32">
                                    "Nous sommes inspirés par la Nature et motivés à faire valoir votre beauté... "<br>
                                    <span class="text-base font-bold">La Directrice Générale Adjointe de IBC</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <!-- VALORS -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="col-12 flex flex-col items-center">
                        <h1 class="text-center text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Nos valeurs</h1>
                        <div class="bg-lightgreen p-1 mt-3 w-28"></div>
                    </div>
                    <div class="container mx-auto py-20">
                        <div class="row justify-around">
                            <div class="col-12 col-sm-6 col-md-3 flex flex-col items-center">
                                <q-img fit="cover" width="100px" src="@/assets/media/valors/ibc-international-bio-cosmetics-valors-quality.svg" no-spinner no-transition/>
                                <p class="text-lightgreen text-center text-xl text-bold mt-2 px-20">Qualité</p>
                                <p class="text-mineralgreen text-center text-md mt-2 px-20">Plus qu'une valeur un engagement. Car vous mériter le meilleur.</p>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                                <q-img fit="cover" width="100px" src="@/assets/media/valors/ibc-international-bio-cosmetics-valors-passion.svg" no-spinner no-transition/>
                                <p class="text-lightgreen text-center text-xl text-bold mt-2 px-20">Passion</p>
                                <p class="text-mineralgreen text-center text-md mt-2 px-20">Nous avons le coeur à l'ouvrage. C'est un gage de réussite. Passionnés et motivés pour vous.</p>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                                <q-img fit="cover" width="100px" src="@/assets/media/valors/ibc-international-bio-cosmetics-valors-innovation.svg" no-spinner no-transition/>
                                <p class="text-lightgreen text-center text-xl text-bold mt-2 px-20">Innovation</p>
                                <p class="text-mineralgreen text-center text-md mt-2 px-20">Elle est au coeur de notre métier, nous sommes sans cesse à la recherche de ce qui crée la différence pour vous.</p>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                                <q-img fit="cover" width="100px" src="@/assets/media/valors/ibc-international-bio-cosmetics-valors-eco.svg" no-spinner no-transition/>
                                <p class="text-lightgreen text-center text-xl text-bold mt-2 px-20">Ecocitoyenneté</p>
                                <p class="text-mineralgreen text-center text-md mt-2 px-20">Respectueux de la nature. Engagés pour un environnement sain et préservé. Telle demeure notre plus grande valeur.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/Navbar"
import footerbar from "@/views/shared/Footer"
import { useMeta } from "quasar"

export default {
    name: "Enterprise",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Notre Entreprise', // sets document title
        })
    }
};
</script>